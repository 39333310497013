import { useQuery } from "@tanstack/react-query";
import { VideoApi } from "../../Api";
export const usePopularVideos = ()=>{
    const videoApi = new VideoApi();
    const usePopularVideosQuery = (pagination)=>useQuery([
            "videos",
            "popular",
            pagination
        ], ()=>videoApi.getPopularVideos(pagination), {
            keepPreviousData: true
        });
    return {
        usePopularVideosQuery
    };
};
