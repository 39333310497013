import { takeNextLooped } from "../../../utils/loop";
export class Stream {
    static fromPlaylist(playlist, timestamp, playlistId) {
        const currentlyPlayingMediaSummary = findMediaAt(playlist || [], timestamp) || {
            item: {
                mediaId: "",
                title: "Loading",
                description: "",
                image: "https://cdn.jwplayer.com/v2/media/jqErKmBL/poster.jpg?width=720",
                duration: 0
            },
            timestamp,
            index: 0
        };
        return new this(currentlyPlayingMediaSummary.timestamp, "https://cdn.jwplayer.com/feeds/".concat(playlistId, ".rss"), currentlyPlayingMediaSummary, takeNextLooped(playlist || [], currentlyPlayingMediaSummary.item, (media, nextMedia)=>media.mediaId === nextMedia.mediaId, 5));
    }
    constructor(timestamp, streamLink, currentlyPlayingMediaSummary, nextMedia){
        this.timestamp = timestamp;
        this.streamLink = streamLink;
        this.currentlyPlayingMediaSummary = currentlyPlayingMediaSummary;
        this.nextMedia = nextMedia;
    }
}
export function findMediaAt(list, timestamp) {
    const completeDuration = list.reduce((acc, item)=>acc + item.duration, 0);
    const normalizedTimestamp = timestamp % completeDuration;
    let composedDuration = 0;
    for (const [index, item] of list.entries()){
        if (normalizedTimestamp < composedDuration + item.duration) return {
            index,
            item,
            timestamp: normalizedTimestamp - composedDuration
        };
        composedDuration += item.duration;
    }
    return undefined;
}
