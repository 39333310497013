import React from 'react';
import classNames from 'classnames';

interface OutsidePageWrapperProps {
  children: React.ReactNode;
  className?: string;
}

export const OutsidePageWrapper = ({
  children,
  className,
}: OutsidePageWrapperProps) => (
  <div
    className={classNames(
      'p-4 w-full max-w-screen-2xl mx-auto md:p-[30px] lg:p-10',
      className
    )}
  >
    {children}
  </div>
);
