import React from 'react';
import classNames from 'classnames';

interface InsidePageWrapperProps {
  children: React.ReactNode;
  className?: string;
  id?: string;
}

export const InsidePageWrapper = ({
  children,
  className,
  id,
}: InsidePageWrapperProps) => (
  <div
    className={classNames('mt-6 w-full max-w-6xl mx-auto', className)}
    id={id}
  >
    {children}
  </div>
);
