import React from 'react';
import { IconProps } from '../Icons.interface';

export const VolumeOffFilledIcon: React.FC<IconProps> = ({ className }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.383 3.07612C9.56569 3.15186 9.72181 3.28007 9.83163 3.44454C9.94146 3.60901 10 3.80235 10 4.00012V16.0001C9.99996 16.1979 9.94129 16.3912 9.8314 16.5556C9.72152 16.72 9.56535 16.8481 9.38265 16.9238C9.19995 16.9995 8.99892 17.0193 8.80497 16.9807C8.61102 16.9421 8.43285 16.8469 8.293 16.7071L4.586 13.0001H2C1.73478 13.0001 1.48043 12.8948 1.29289 12.7072C1.10536 12.5197 1 12.2653 1 12.0001V8.00012C1 7.7349 1.10536 7.48055 1.29289 7.29301C1.48043 7.10547 1.73478 7.00012 2 7.00012H4.586L8.293 3.29312C8.43285 3.15318 8.61105 3.05787 8.80508 3.01925C8.9991 2.98062 9.20023 3.00041 9.383 3.07612ZM12.293 7.29312C12.4805 7.10565 12.7348 7.00033 13 7.00033C13.2652 7.00033 13.5195 7.10565 13.707 7.29312L15 8.58612L16.293 7.29312C16.3852 7.19761 16.4956 7.12142 16.6176 7.06901C16.7396 7.01661 16.8708 6.98902 17.0036 6.98787C17.1364 6.98671 17.2681 7.01201 17.391 7.06229C17.5138 7.11258 17.6255 7.18683 17.7194 7.28072C17.8133 7.37461 17.8875 7.48627 17.9378 7.60916C17.9881 7.73206 18.0134 7.86374 18.0123 7.99652C18.0111 8.1293 17.9835 8.26052 17.9311 8.38252C17.8787 8.50452 17.8025 8.61487 17.707 8.70712L16.414 10.0001L17.707 11.2931C17.8892 11.4817 17.99 11.7343 17.9877 11.9965C17.9854 12.2587 17.8802 12.5095 17.6948 12.6949C17.5094 12.8803 17.2586 12.9855 16.9964 12.9878C16.7342 12.9901 16.4816 12.8893 16.293 12.7071L15 11.4141L13.707 12.7071C13.5184 12.8893 13.2658 12.9901 13.0036 12.9878C12.7414 12.9855 12.4906 12.8803 12.3052 12.6949C12.1198 12.5095 12.0146 12.2587 12.0123 11.9965C12.01 11.7343 12.1108 11.4817 12.293 11.2931L13.586 10.0001L12.293 8.70712C12.1055 8.51959 12.0002 8.26528 12.0002 8.00012C12.0002 7.73495 12.1055 7.48064 12.293 7.29312Z"
      fill="currentColor"
    />
  </svg>
);
