import { HttpClient } from "@parkschannel/next-utils";
export class StreamApi {
    getStreamPlaylist() {
        return this.client.get(this.baseUrl, {}, false);
    }
    constructor(client = new HttpClient()){
        this.client = client;
        this.baseUrl = "/api/media/stream";
    }
}
